/**
 * This is a page for view only assessment
 */
import React, { useState, useMemo } from 'react'
import { Box } from '@mui/material'
import get from 'lodash/get'
import { useMutation } from 'utils/apollo'
import MainLayout from 'components/containers/main/Main'
import useGetAssessments from './utils/useGetAssessments'
import { PageLoader } from '../../components'
import { useGetUserWithoutRefresh } from '../../utils/hooks/useGetUser'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { ACTIONS } from './utils/constants'
import { setAssessmentStatus } from '../../store/modules/sessions'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useGetAssessmentSessions } from 'utils/hooks/useGetSessions'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'

// survey js
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { AssessmentSuccessPage } from './assessment-success-page'
import { themeJson } from './utils/theme'
import { ScrollableComponent } from 'components/scrollable-component'
import 'survey-core/defaultV2.min.css'
import './styles/nav-button-intro-outro.css'

import { parseMetadata } from './utils/parse-metadata'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

const useGetClientUpdateAssessments = ({ userId }) => {
  const [updateSession, { loading: updateLoading }] = useMutation(UPDATE_SESSION)
  const { selectedUser, loading: loadingGetUser } = useGetUserWithoutRefresh(userId)
  const loading = updateLoading || loadingGetUser

  if (selectedUser && updateSession) {
    return {
      loading,
      selectedUser,
      updateSession,
    }
  } else {
    return { loading }
  }
}

export default function ClientUpdateAssessments() {
  const navigate = useNavigate()
  const { pathname: assessmentPathname } = useLocation()
  const params = useParams()

  const { IN_PROGRESS, SENT_TO_CLIENT, COMPLETED, isSent, isInProgressByClient } = ASSESSMENT_STATUS

  const sessionId = get(params, 'sessionId', -1)
  const userId = get(params, 'userId', -1)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [onClickLoading, setOnClickLoading] = useState(false)

  const assessmentSessions = useSelector((state) => get(state, 'assessments.sessions', []))
  const filterSession = assessmentSessions.find(({ id }) => id === parseInt(sessionId))
  const productEventId = get(filterSession, 'productEventId', {})

  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const selectedAssessment = productEvents.find(({ id }) => id === productEventId)

  // hooks
  const { loading: loadingGetAssessments } = useGetAssessments({})
  const { loading: loadingHook, selectedUser, updateSession } = useGetClientUpdateAssessments({
    userId,
    sessionId,
  })
  useGetAssessmentSessions()

  const [assessmentData, setAssessmentData] = useState({
    lastVisitedPage: 0,
    sessionData: {},
  })
  useOnValueChange(JSON.stringify({ filterSession }), () => {
    const sessionData = get(filterSession, 'data', {})
    setAssessmentData({
      lastVisitedPage: sessionData?.currentPageNumber || sessionData?.currentPageNo || 0,
      sessionData,
    })
  })

  const loading = onClickLoading || loadingHook || loadingGetAssessments

  const onClick = (eventStatus) => async () => {
    setOnClickLoading(true)
    const currentPageNo = survey.currentPageNo
    const status =
      isSent(assessmentData.sessionData.status) && isInProgressByClient(eventStatus)
        ? SENT_TO_CLIENT
        : eventStatus

    const score = status === COMPLETED ? getAssessmentScore(survey.data, metadata) : undefined
    const data = { answers: { ...survey.data, score }, status, currentPageNo }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      dispatch(setAssessmentStatus({ sessionId, status }))
      enqueueSnackbar(ACTIONS[eventStatus].message, {
        variant: ACTIONS[eventStatus].variant,
        action: CloseSnackbarAction,
      })
      if (eventStatus === COMPLETED) {
        setIsComplete(true)
      } else {
        navigate('/')
      }
    } catch (error) {
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
    } finally {
      setOnClickLoading(false)
    }
  }

  const metadata = get(selectedAssessment, 'metadata', {})
  const { survey: _survey, assessmentName, isIntakeForm } = parseMetadata(metadata)

  const survey = useMemo(() => {
    let hasAddedNavButton = false
    const model = new Model(_survey)
    model.applyTheme(themeJson)

    // since we're opening a saved assessment, we might not start with info page.

    // page changes
    model.onStarted.add(() => {
      if (!hasAddedNavButton) {
        survey.addNavigationItem({
          id: 'sv-nav-clear-page',
          title: 'Complete Later',
          action: handleSave,
          css: 'nav-button',
          innerCss: 'sd-btn nav-input',
        })
        hasAddedNavButton = true
      }
    })

    model.onCurrentPageChanged.add((sender) => {
      if (sender.currentPage.name === 'midText' || isIntakeForm) {
        survey.pageNextText = 'Next'
      } else {
        survey.pageNextText = 'Skip'
      }

      // do not autosave on last page
      if (!sender.isLastPage) {
        autoSave(sender)
      }
    })

    model.onProgressText.add((_, options) => {
      options.text = options.text.replace('questions', 'items')
      if (isIntakeForm) {
        options.text = options.text.replace('30', '28')
        options.text = options.text.replace('31', '29')
      }
    })

    return model
  }, [assessmentName, assessmentData])

  const currentUserId = useSelector((state) => get(state, 'auth.user.id', -1))
  const isOwnDashboard = parseInt(userId) === currentUserId
  const tabs = [
    {
      text: isOwnDashboard ? (
        'My dashboard'
      ) : (
        <>
          <b>{selectedUser.fullName}</b> {!!selectedUser.email && <>({selectedUser.email})</>}
        </>
      ),

      url: isOwnDashboard ? '/' : `/dashboard/${userId}`,
      isActive: (pathname) =>
        matchPath({ path: isOwnDashboard ? '/' : `/dashboard/${userId}` }, pathname),
    },
    {
      text: 'View Assessment',
      url: assessmentPathname,
      isActive: (pathname) => matchPath({ path: assessmentPathname }, pathname),
    },
  ]

  const autoSave = async (sender) => {
    try {
      const data = {
        answers: { ...sender.data },
        status: IN_PROGRESS,
        currentPageNo: Math.max(survey.currentPageNo - 1, 0),
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      // do not auto update sent to client or completed
      if (isInProgressByClient(assessmentData.sessionData.status)) {
        dispatch(autoSaveAssessment({ session }))
      }
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
      })
    }
  }

  const handleSave = async () => {
    try {
      setOnClickLoading(true)
      const data = {
        answers: { ...survey.data },
        status: IN_PROGRESS,
        currentPageNo: Math.max(survey.currentPageNo - 1, 0),
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      navigate(`/`)
      enqueueSnackbar(ACTIONS[IN_PROGRESS].message, {
        variant: ACTIONS[IN_PROGRESS].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
    } finally {
      setOnClickLoading(false)
    }
  }

  const [isComplete, setIsComplete] = useState(false)

  return (
    <MainLayout title="Continue Assessment" tabs={tabs} hideHeader>
      {loading && <PageLoader />}
      <Box
        sx={{
          backgroundColor: '#EEF7F3',
          width: '100%',
          height: '100%',
          overflowXY: 'clip',
        }}
      >
        {!loading && !isComplete && (
          <Survey
            model={survey}
            mode={'default'}
            data={assessmentData.sessionData?.answers}
            onComplete={onClick(COMPLETED)}
            widthMode="responsive"
            showNavigationButtons="bottom"
            goNextPageAutomatic={true}
            currentPageNo={assessmentData.lastVisitedPage}
            questionsOnPageMode="questionPerPage"
            firstPageIsStarted={!isInProgressByClient(assessmentData.sessionData?.answers)}
            allowCompleteSurveyAutomatic={false}
            showProgressBar="belowHeader"
            progressBarType="questions"
          />
        )}
        {isComplete && <AssessmentSuccessPage navigateUrl={'/'} />}
      </Box>
      <ScrollableComponent autoFocus={true}>
        <div id="footer" style={{ height: '1px', marginTop: 'auto' }} />
      </ScrollableComponent>
    </MainLayout>
  )
}
