/**
 * This is a page for updating existing assessment
 */
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Stack, Box } from '@mui/material'
import get from 'lodash/get'
import { useMutation } from 'utils/apollo'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

import { useSnackbar } from 'notistack'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { ACTIONS } from './utils/constants'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useDispatch, useSelector } from 'react-redux'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'

// survey js
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { AssessmentSuccessPage } from './assessment-success-page'
import { themeJson } from './utils/theme'
import { ScrollableComponent } from 'components/scrollable-component'
import 'survey-core/defaultV2.min.css'
import './styles/nav-button-intro-outro.css'

// print components
import moment from 'utils/moment'
import { Button, Typography, Grid, GlobalStyles } from '@mui/material'
import globalStyles from 'styles/GlobalStyles'
import { parseMetadata } from './utils/parse-metadata'
import { isEmpty } from 'lodash'

const HeaderTitle = ({ pageTitle, name }) => (
  <span>
    {pageTitle} | <span className="text-link font-semibold">{name}</span>
  </span>
)
export default function UpdateAssessment2() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  // react component states and ref
  const componentRef = useRef(null)
  const [showPrintObjects, setShowPrintObjects] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const { userId: _userId, sessionId: _sessionId } = useParams()
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const sessionId = parseInt(_sessionId)
  const userId = parseInt(_userId)

  const {
    SENT_TO_CLIENT,
    IN_PROGRESS,
    COMPLETED,
    isSent,
    isInProgress,
    isCompleted,
  } = ASSESSMENT_STATUS

  // search for assessment data
  const {
    setHideHeader,
    setTitle,
    refetch,
    setLoadingStateWithTimer,
    onLoadKPI,
    loadAssessmentsForUpsert,
    loading,
  } = useOutletContext()

  const { selectedUser } = useGetUserWithoutRefresh(userId)

  const users = useSelector((state) => get(state, 'assessments.userData', []))
  const { fullName, email } = users.find(({ id }) => parseInt(userId) === id) ?? {}
  const name = email ? `${fullName} (${email})` : fullName

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted: () => {
      onLoadKPI()
    },
  })
  const [assessmentData, setAssessmentData] = useState({})

  // we need to watch this state and rerender..
  const { sessionData = {}, title, updatedAt, metadata } = assessmentData
  const { survey: _survey, isIntakeForm } = parseMetadata(metadata)

  useOnValueChange(JSON.stringify({ userId, sessionId, productEvents, sessionData }), async () => {
    setHideHeader(true)
    if (userId && sessionId) {
      const response = await loadAssessmentsForUpsert({
        userIds: [parseInt(userId)],
        sessionId,
      })
      setAssessmentData({
        ...response,
        isInProgressState: isInProgress(response.sessionData.status),
        isCompletedState: isCompleted(response.sessionData.status),
      })

      if (isInProgress(response.sessionData.status)) {
        setTitle(<HeaderTitle pageTitle={'Continue Assessment'} name={name} />)
      } else {
        setTitle(<HeaderTitle pageTitle={'View Assessment'} name={name} />)
      }
    }
  })

  // new assessment Data retreives data on load

  // if a user clicks back, we never return with a filter
  const onClick = (statusPassIn) => async () => {
    // if you've sent to client, status will be locked as sent to client until client completes assessments
    const status =
      isSent(sessionData.status) && isInProgress(statusPassIn) ? SENT_TO_CLIENT : statusPassIn
    await setLoadingStateWithTimer(true)
    const score =
      status === 'Completed' ? getAssessmentScore({ ...survey.data }, metadata) : undefined
    const data = { answers: { ...survey.data, score }, status, currentPageNo: survey.currentPageNo }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await refetch()
      setIsComplete(true)
      enqueueSnackbar(ACTIONS[status].message, {
        variant: ACTIONS[status].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoadingStateWithTimer(false)
    }
  }

  const survey = useMemo(() => {
    let hasAddedNavButton = false
    const model = new Model(_survey)
    model.applyTheme(themeJson)

    // page changes
    model.onCurrentPageChanged.add((sender) => {
      if (!hasAddedNavButton && isInProgress(sessionData.status)) {
        survey.addNavigationItem({
          id: 'sv-nav-clear-page',
          title: 'Complete Later',
          action: handleSave,
          css: 'nav-button',
          innerCss: 'sd-btn nav-input',
        })
        hasAddedNavButton = true
      }
      if (sender.currentPage.name === 'midText' || isIntakeForm) {
        survey.pageNextText = 'Next'
      } else {
        survey.pageNextText = 'Skip'
      }
      autoSave(sender)
    })

    model.onProgressText.add((_, options) => {
      options.text = options.text.replace('questions', 'items')
      if (isIntakeForm) {
        options.text = options.text.replace('30', '28')
        options.text = options.text.replace('31', '29')
      }
    })

    return model
  }, [title])

  // save assessment and refresh dashboard to see current assessment
  const navigate = useNavigate()
  const handleSave = async () => {
    try {
      setLoadingStateWithTimer(true, 'Saving Assessment', 5000)
      const data = {
        answers: { ...survey.data },
        status: IN_PROGRESS,
        currentPageNo: Math.max(survey.currentPageNo - 1, 0),
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await refetch()
      navigate(`/assessments/${userId}`, { state: { hideGettingStarted: true } })
      enqueueSnackbar(ACTIONS[IN_PROGRESS].message, {
        variant: ACTIONS[IN_PROGRESS].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoadingStateWithTimer(false)
    }
  }

  useEffect(() => {
    setLoadingStateWithTimer(true)
  }, [])

  const lastVisitedPage = sessionData?.currentPageNumber || sessionData?.currentPageNo

  const autoSave = async (sender) => {
    try {
      const data = {
        answers: { ...sender.data },
        status: IN_PROGRESS,
        currentPageNo: Math.max(survey.currentPageNo - 1, 0),
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      // do not auto update sent to client or completed
      if (isInProgress(sessionData.status)) {
        dispatch(autoSaveAssessment({ session }))
      }
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
      })
    }
  }

  const handleOnBeforeGetContent = () => {
    setShowPrintObjects(true)
  }

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
    // eslint-disable-next-line
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
  })

  const setStateThenPrint = async () => {
    setShowPrintObjects(true)
    handlePrint()
    setShowPrintObjects(false)
  }

  return (
    <Box
      sx={{
        backgroundColor: '#EEF7F3',
        width: '100%',
        height: '100%',
        overflowXY: showPrintObjects ? 'inherit' : 'clip',
      }}
    >
      {isIntakeForm && !isInProgress(sessionData.status) && (
        <Stack justifyContent="flex-end">
          <Button
            onClick={setStateThenPrint}
            variant="contained"
            sx={{ marginLeft: 'auto', marginRight: 3, marginY: 1, width: 'fit-content' }}
          >
            Print
          </Button>
        </Stack>
      )}
      <div ref={componentRef} className={showPrintObjects ? 'pt-3 px-8' : ''}>
        {showPrintObjects && (
          <>
            {isIntakeForm && <GlobalStyles styles={globalStyles} />}
            <Grid container alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item>
                <img
                  src="/images/unyte-logo.png"
                  alt="Unyte"
                  style={{ width: '50px', marginTop: '25px' }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5">{title}</Typography>
                <Grid item>
                  <Typography variant="body1">{`${selectedUser.fullName} (${selectedUser.email})`}</Typography>
                  <Typography variant="body1">
                    {updatedAt
                      ? moment
                          .withoutTimezone(updatedAt)
                          .utcOffset(0)
                          .format('MM/DD/YYYY')
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {!isComplete && !loading && !isEmpty(_survey) && (
          <Stack>
            <Box sx={{ height: showPrintObjects ? '100%' : '100vh' }}>
              <Survey
                model={survey}
                mode={!isInProgress(sessionData.status) ? 'display' : 'edit'}
                data={sessionData?.answers}
                currentPageNo={lastVisitedPage}
                onComplete={onClick(COMPLETED)}
                widthMode="responsive"
                showProgressBar="belowHeader"
                showNavigationButtons="bottom"
                goNextPageAutomatic={true}
                progressBarType="questions"
                firstPageIsStarted={false}
                questionsOnPageMode={
                  isInProgress(sessionData.status) ? 'questionPerPage' : 'singlePage'
                }
              />
            </Box>
            <ScrollableComponent autoFocus={true}>
              <div id="footer" style={{ height: '1px', marginTop: 'auto' }} />
            </ScrollableComponent>
          </Stack>
        )}
      </div>
      {isComplete && <AssessmentSuccessPage />}
    </Box>
  )
}
